import { useEffect } from "react";
import "./App.scss";
import Menu from "./common/Menu/Menu";
import { Route, Routes } from "react-router-dom";
import Home from "./components/Home/Home";
import Footer from "./common/Footer/Footer";
import Contact from "./components/Contact/Contact";
import Products from "./components/Products/Products";
import Houses from "./components/Products/Houses/Houses";
import Lands from "./components/Products/Houses/Lands/Lands";
import BOD from "./components/About/BOD/BOD";
import SingleHouses from "./components/Products/Houses/single-Houses/singleHouses";
import SIngleLand from "./components/Products/Houses/Land-Houses/Land-Houses";
import SIngleLand2 from "./components/Products/Houses/Land-Houses/Land-Houses2";
import SIngleLand3 from "./components/Products/Houses/Land-Houses/Land-Houses3";
import SIngleLand4 from "./components/Products/Houses/Land-Houses/Land-Houses4";
import SIngleLand5 from "./components/Products/Houses/Land-Houses/Land-Houses5";
import SIngleLand6 from "./components/Products/Houses/Land-Houses/Land-Houses6";
import SIngleLand7 from "./components/Products/Houses/Land-Houses/Land-Houses7";
import SIngleLand8 from "./components/Products/Houses/Land-Houses/Land-Houses8";
import SIngleLand9 from "./components/Products/Houses/Land-Houses/Land-Houses9";
import SIngleLand11 from "./components/Products/Houses/Land-Houses/Land-Houses11";
import AdePrimeEstate from "./components/Products/Houses/Land-Houses/adePrimeEstate";
import WhoAreWe from "./components/About/WhoAreWe/WhoAreWe";
import EMS from "./components/About/EM/EM";
import NONEMS from "./components/About/NONEM/NONEM";
import FQA from "./components/About/FQA/FQA";
import Blog from "./components/About/Blog/Blog";
import Certifications from "./components/Certifications/Certifications";
import Inspection from "./components/Inspection/Inspection";
import Blog1 from "./components/About/Blog/Layout/blog1";
import Blog2 from "./components/About/Blog/Layout/blog2";
import Blog3 from "./components/About/Blog/Layout/blog3";
import Blog4 from "./components/About/Blog/Layout/blog4";
import Blog5 from "./components/About/Blog/Layout/blog5";
import Blog6 from "./components/About/Blog/Layout/blog6";
import SingleHouses2 from "./components/Products/Houses/single-Houses/singleHouses2";
import { NotFound } from "./components/404/404";
import SIngleLand10 from "./components/Products/Houses/Land-Houses/Land-Houses10";
import EstatesInIbejuLekki from "./components/About/Blog/Layout/estates-in-ibeju-lekki";
import ZylusGroupInternational from "./components/About/Blog/Layout/zylus-group-international";
import LagosRealEstate from "./components/About/Blog/Layout/lagos-real-estate";
import PioneeringInternational from "./components/About/Blog/Layout/pioneeringInternational";
import Index from "./components/Testimonial";
import Webiner from "./components/webiner";
import ZylusCout from "./components/Products/Houses/single-Houses/zylusCout";
import TrustlineCapitaLimited from "./components/About/Blog/Layout/trustline-capital-limited";
import AOS from "aos";
import "aos/dist/aos.css";
// import Index from './components/Admin';
// import Overview from './components/Admin/overview/overview';
// import MakeEnquiry from './components/Admin/MakeEnquiry/MakeEnquiry';
// import Reservation from './components/Admin/Reservation/Reservation';
// import ReservationHub from './components/Admin/ReservationHub/ReservationHub';
// import AdminContact from './components/Admin/Contact/Contact';
// import AdminContactHub from './components/Admin/ContactHub/ContactHub';
// import { NotFound } from './components/NotFound/NotFound';
// import { useSelector } from "react-redux"

function App() {
  // const user = useSelector((state) => state.login)
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <div className="App">
      {window.location.pathname.split("/")[1] === "webinar" ? null : <Menu />}
      {/* <Menu /> */}
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/products" element={<Products />} />
        <Route exact path="/houses" element={<Houses />} />
        <Route exact path="/lands" element={<Lands />} />
        <Route exact path="/single-houses" element={<SingleHouses />} />
        <Route exact path="/single-land" element={<SIngleLand />} />
        <Route exact path="/tiwa-garden-city" element={<SIngleLand2 />} />
        <Route exact path="/lekki-pride-III" element={<SIngleLand3 />} />
        <Route exact path="/zylus-court-phase-II" element={<SIngleLand4 />} />
        <Route exact path="/arizon-estate" element={<SIngleLand8 />} />
        <Route exact path="/hilton-city" element={<SIngleLand5 />} />
        <Route exact path="/hilton-city-west" element={<SIngleLand7 />} />
        <Route exact path="/treasure-park" element={<SIngleLand6 />} />
        <Route exact path="/arizon-estate" element={<SIngleLand8 />} />
        <Route exact path="/western-hilltop" element={<SIngleLand9 />} />
        <Route exact path="/eti-ile" element={<SIngleLand10 />} />
        <Route exact path="/signum" element={<SIngleLand11 />} />
        <Route exact path="/ade-prime-estate" element={<AdePrimeEstate />} />
        <Route exact path="/board-of-directors" element={<BOD />} />
        <Route exact path="/executive-management" element={<EMS />} />
        <Route exact path="/non-executive-management" element={<NONEMS />} />
        <Route exact path="/blog" element={<Blog />} />
        <Route exact path="/faq" element={<FQA />} />
        <Route exact path="/who-are-we" element={<WhoAreWe />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/certifications" element={<Certifications />} />
        <Route exact path="inspection" element={<Inspection />} />
        <Route exact path="/book-an-inspection" element={<Inspection />} />
        <Route exact path="/blog1" element={<Blog1 />} />
        <Route exact path="/blog2" element={<Blog2 />} />
        <Route exact path="/blog3" element={<Blog3 />} />
        <Route exact path="/blog4" element={<Blog4 />} />
        <Route exact path="/blog5" element={<Blog5 />} />
        <Route exact path="/blog6" element={<Blog6 />} />
        <Route exact path="/webinar" element={<Webiner />} />
        <Route exact path="/zylus-court-extention" element={<ZylusCout />} />
        <Route
          exact
          path="/trustline-capita-limited"
          element={<TrustlineCapitaLimited />}
        />
        <Route
          exact
          path="/estates-in-ibeju-lekki"
          element={<EstatesInIbejuLekki />}
        />
        <Route
          exact
          path="/zylus-group-international"
          element={<ZylusGroupInternational />}
        />
        <Route exact path="/lagos-real-estate" element={<LagosRealEstate />} />
        <Route
          exact
          path="/pioneering-international-real-estate-excellence-in-nigeria"
          element={<PioneeringInternational />}
        />
        <Route exact path="/testimonial" element={<Index />} />
        <Route exact path="/single-houses2" element={<SingleHouses2 />} />
        <Route exact path="*" element={<NotFound />} />

        {/* {user.count ?
          <Route path="admin" element={<Index />}>
            <Route path="overview">
              <Route index={true} element={<Overview />} />
            </Route>

            <Route path="make-enquiry">
              <Route index={true} element={<MakeEnquiry />} />
            </Route>

            <Route path="reservation">
              <Route index={true} element={<Reservation />} />
            </Route>

            <Route path="reservationhub">
              <Route index={true} element={<ReservationHub />} />
            </Route>

            <Route path="contact">
              <Route index={true} element={<AdminContact />} />
            </Route>

            <Route path="contacthub">
              <Route index={true} element={<AdminContactHub />} />
            </Route>
          </Route>
          :
          <Route path="*" element={<NotFound />} />} */}
      </Routes>
      {window.location.pathname.split("/")[1] === "webinar" ? null : <Footer />}
      {/* <Footer /> */}
    </div>
  );
}

export default App;
