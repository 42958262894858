import React, { useEffect } from "react";
import blogStyle from "./Blog1.module.scss";
import tiwaiMirn2 from "../../../../image/Team1.png";
import adesanya from "../../../../image/adesanya.jpg";
import thankgiven1 from "../../../../image/Team2.png";
import { Link } from "react-router-dom";

function TrustlineCapitaLimited(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className={blogStyle.holdALl}>
      <div>
        <div className={blogStyle.AllCategory}>
          <div className={blogStyle.AllCategory2}>
            <div>
              <div>
                <h3>
                  Trustline Capital Limited Subsidiary of Zylus Group
                  International
                </h3>
              </div>
              <div>
                <p className={blogStyle.Developtheskills}>
                  At Trustline Capital Limited, we are your trusted partners in
                  financial management, providing tailored investment solutions
                  for individuals, institutions, and organizations. As a
                  subsidiary of Zylus Group International, we leverage the
                  strength and resources of a globally renowned brand to deliver
                  unparalleled financial services. Our goal is to help you
                  secure your financial future through expert fund management
                  and innovative investment strategies, whether you’re in
                  Nigeria or the diaspora.
                </p>
              </div>
              <div className={blogStyle.HousingPublished}>
                <span className={blogStyle.HousingPublished2}>Subsidiary</span>
                <span className={blogStyle.HousingPublished3}>Published</span>
              </div>
              {/* <button className={blogStyle.learnMore}>Read More</button> */}
              <div className={blogStyle.ADMINMARCH}>
                <span>ADMIN. Jan 21, 2025</span>
              </div>
            </div>
          </div>
          <div className={blogStyle.Rectangle123}>
            <img
              src={tiwaiMirn2}
              alt="Ibeju Lekki"
              style={{ height: "300px" }}
            />
            <img src={adesanya} alt="Ibeju Lekki" style={{ height: "300px" }} />
          </div>
        </div>

        <div className={blogStyle.AllCategory}>
          <div className={blogStyle.AllCategory2}>
            {/* <div className={blogStyle.AllIbejuLekki1Pro}>
              <div className={blogStyle.AllIbejuLekki}>
                <p>
                  The grand opening ceremony, held on Jan 31, 2024, was a
                  testament to Zylus Group's unwavering commitment to pushing
                  boundaries and fostering a culture of ingenuity. Distinguished
                  guests, industry leaders, and esteemed clients from around the
                  globe gathered to commemorate this momentous occasion.
                </p>
              </div>

              <div className={blogStyle.AllIbejuLekki}>
                <p>
                  Upon arrival, attendees were greeted by a breathtaking
                  architectural marvel, epitomizing modernity and
                  sophistication. The sleek design, characterized by expansive
                  glass facades and contemporary interiors, mirrors Zylus
                  Group's forward-thinking ethos.
                </p>
              </div>
            </div> */}

            <div className={blogStyle.AllIbejuLekki1Pro}>
              <div className={blogStyle.AllIbejuLekki}>
                <h3>Who We Are:</h3>
                <p>
                  Trustline Capital Limited is a duly registered capital market
                  player, regulated by the Securities and Exchange Commission
                  (SEC). With a solid reputation for reliability, efficiency,
                  and trustworthiness, we aim to redefine fund management and
                  investment advisory services. As part of the Zylus Group
                  International family, we are committed to maintaining the high
                  standards and values that define our parent company.
                </p>
              </div>

              <div className={blogStyle.AllIbejuLekki}>
                <h3>Our Services: Invest with Confidence:</h3>
                <p>
                  We understand that every client is unique, which is why we
                  offer a wide range of products and services designed to meet
                  your specific needs. Our offerings include:
                </p>
                <p>
                  Fund/Portfolio Management: Expertly handling your investments
                  to maximize returns while minimizing risks.
                </p>
                <p>
                  Liquidity Management: Ensuring your financial resources remain
                  flexible, accessible, and optimized for growth.
                </p>
                <p>
                  Securities Investment Banking: Crafting secure and strategic
                  investment solutions to help you achieve your financial goals.
                </p>
                <p>
                  Investment Advisory: Providing you with professional insights
                  and guidance to make informed decisions tailored to your
                  financial objectives.
                </p>
                <p>
                  Our services are designed to cater to clients at various
                  financial levels, aligning with our mission to build wealth
                  for all.
                </p>
              </div>

              <div className={blogStyle.AllIbejuLekki}>
                <h3>Our Core Values :</h3>
                At Trustline Capital Limited, our operations are guided by the
                following values:
                <ul>
                  <li>
                    Trust: We build strong, reliable relationships with our
                    clients.
                  </li>
                  <li>
                    Objectivity: We provide unbiased and transparent financial
                    strategies.
                  </li>
                  <li>
                    Integrity: Ethical practices are at the heart of everything
                    we do.
                  </li>
                  <li>
                    Integrity: Ethical practices are at the heart of everything
                    we do.
                  </li>
                  <li>
                    Excellence: We deliver superior results through meticulous
                    planning and execution.
                  </li>
                  <li>
                    Confidentiality: Your financial information is handled with
                    the utmost care and security.
                  </li>
                  <li>Teamwork: Collaboration and unity drive our success.</li>
                </ul>
              </div>

              <div className={blogStyle.AllIbejuLekki}>
                <h3>Why Choose Trustline Capital Limited? :</h3>
                <p>
                  Subsidiary of Zylus Group International: As part of a trusted
                  global brand, we have the backing and expertise needed to
                  deliver world-class financial services.
                </p>
                <p>
                  Regulated and Trusted: As a SEC-regulated entity, we adhere to
                  the highest standards of transparency and compliance.
                </p>
                <p>
                  Customized Solutions: We tailor our services to meet your
                  unique financial needs and goals.
                </p>
                <p>
                  Commitment to Excellence: Our team of experts is dedicated to
                  providing top-tier services and exceptional results.
                </p>
                <p>
                  Wealth Creation for All: Whether you’re just starting out or
                  managing significant assets, we have solutions designed for
                  every stage of wealth creation.
                </p>
              </div>

              <div className={blogStyle.AllIbejuLekki}>
                <h3>Building a Stronger Financial Future :</h3>
                <p>
                  At Trustline Capital Limited, we believe in empowering
                  individuals and businesses to achieve financial independence.
                  By offering accessible and innovative investment solutions, we
                  ensure that wealth creation is not just a possibility but a
                  reality for all.
                </p>
              </div>
              <div className={blogStyle.AllIbejuLekki}>
                <h3>Experience the Trustline Advantage :</h3>
                <p>
                  Whether you want to grow your wealth, secure your future, or
                  make sound investment decisions, Trustline Capital Limited is
                  here to help. Visit our website at{" "}
                  <a
                    href="https://trustlinecapitallimited.com/services/"
                    target="_blank"
                  >
                    trustlinecapitallimited.com
                  </a>{" "}
                  to learn more about our services and start your journey toward
                  financial freedom today. Trustline Capital Limited – Managing
                  Wealth, Building Futures.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TrustlineCapitaLimited;
