import React, {useEffect} from 'react';
import LandStyle from "./Lands.module.scss";
import { FiSearch } from "react-icons/fi";
import { IoCall } from 'react-icons/io5';
import pins from "../../../../image/pins.png"
import drainage from "../../../../image/drainage.png"
import road from "../../../../image/road.png"
import cctvCamera from "../../../../image/cctv-camera.png";
import NewArizon from "../../../../image/New Arizon.png";
import treasure from "../../../../image/treasure.jpg";
import westerHilton2 from "../../../../image/westerHilton2.png";
import hilton from "../../../../image/hilton.webp";
import lekkiprideiii from "../../../../image/lekkiprideiii.jpeg";
import tiwagar from "../../../../image/tiwagar.jpeg";
import westernHilton from "../../../../image/westernHilton.png";
import tiwacom from "../../../../image/tiwacom.jpeg";
import kapital from "../../../../image/kapital.png";
import tiwaiMirn2 from "../../../../image//tiwagerden2.jpg";
import prime1 from "../../../../image//prime1.jpg";
import court from "../../../../image/courtSUB.png";
import zylusgroup from "../../../../image/zylusgroup.jpg";
import signum from "../../../../image/signum.jpg";
import arizonmax from "../../../../image/arizonmax.jpg";
import tiwamax from "../../../../image/tiwamax.jpg";
import etiileNowSelling from "../../../../image/etiileNowSelling.jpg";
import ademax from "../../../../image/ademax.jpg";
import sigmuNowSelling from "../../../../image/sigmuNowSelling.jpg";
import fence from "../../../../image/fence.png";
import { Link, useNavigate } from "react-router-dom";

function Lands(props) {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className={LandStyle.holdAll}>
      <div className={LandStyle.starting}>
        <div
          data-aos="fade-down"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
          data-aos-duration="600"
        >
          <h3>Home is the starting place of love, hope and dreams</h3>
          {/* <p>
            Home is what we deliver to you, not just houses because we dedicate
            a lot attention to details and also implement only the quality.
          </p> */}
        </div>
      </div>

      <div className={LandStyle.holdProperty1}>
        <div
          className={LandStyle.holdProperty2}
          data-aos="fade-down"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
          data-aos-duration="600"
        >
          <div className={LandStyle.holdSearch}>
            <input
              type="search"
              placeholder="Search"
              className={LandStyle.searchKoko}
            />
            <FiSearch className={LandStyle.searchIcon} />
          </div>
          <div className={LandStyle.holdSelect}>
            <select>
              <option>Property Type</option>
              <option>Property Type</option>
            </select>
          </div>
          <div className={LandStyle.holdSelect}>
            <select>
              <option>Price Range</option>
              <option>Property Type</option>
            </select>
          </div>
          <button className={LandStyle.searchButin}>Search</button>
        </div>
      </div>
      <div className={LandStyle.holdDrainage}>
        <div className={LandStyle.holdAllDrainage1}>
          <div
            className={LandStyle.holdAllDrainage2}
            data-aos="fade-right"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
          >
            <div className={LandStyle.holdImage}>
              <h4 className={LandStyle.SalesNo}>Selling</h4>
              <img src={prime1} alt="Ade Prime Estate" />
              <div>
                <h1>Ade Prime Estate</h1>
                <p>
                  <img
                    src={pins}
                    alt="pins"
                    className={LandStyle.locationPoint}
                  />
                  Lagelu, Iwo Road Ibadan
                </p>
              </div>
            </div>
            <p className={LandStyle.landSize}>500 & 300 SQM</p>
            <div className={LandStyle.holdImageText}>
              <div>
                <img src={drainage} alt="drainage" />
                <p>Drainage</p>
              </div>
              <div>
                <img src={road} alt="road" />
                <p>Roads</p>
              </div>
              <div>
                <img src={cctvCamera} alt="cctvCamera" />
                <p>Surveillance</p>
              </div>
              <div>
                <img src={fence} alt="fence" />
                <p>Gated House</p>
              </div>
            </div>
            <div className={LandStyle.holdImageExploreButin}>
              <Link to={"/ade-prime-estate"}>Explore</Link>
            </div>
          </div>

          <div
            className={LandStyle.holdAllDrainage2}
            data-aos="fade-right"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
          >
            <div className={LandStyle.holdImage}>
              <h4 className={LandStyle.SalesNo}>Selling</h4>
              <img src={tiwaiMirn2} alt="kapital" />
              <div>
                <h1>Tiwa Garden City Phase II</h1>
                <p>
                  <img
                    src={pins}
                    alt="pins"
                    className={LandStyle.locationPoint}
                  />
                  Eleran Igbe Bus Stop, Lagos State
                </p>
              </div>
            </div>
            <p className={LandStyle.landSize}>1000 & 500 SQM</p>
            <div className={LandStyle.holdImageText}>
              <div>
                <img src={drainage} alt="drainage" />
                <p>Drainage</p>
              </div>
              <div>
                <img src={road} alt="road" />
                <p>Roads</p>
              </div>
              <div>
                <img src={cctvCamera} alt="cctvCamera" />
                <p>Surveillance</p>
              </div>
              <div>
                <img src={fence} alt="fence" />
                <p>Gated House</p>
              </div>
            </div>
            <div className={LandStyle.holdImageExploreButin}>
              <a href={"https://tiwagardencity.vercel.app/phase2"}>Explore</a>
            </div>
          </div>

          <div
            className={LandStyle.holdAllDrainage2}
            data-aos="fade-left"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
          >
            <div className={LandStyle.holdImage}>
              <h4 className={LandStyle.SalesNo}>Selling</h4>
              <img src={NewArizon} alt="ArizonExplore" />
              <div>
                <h1>Arizon Estate</h1>
                <p>
                  <img
                    src={pins}
                    alt="pins"
                    className={LandStyle.locationPoint}
                  />
                  Eleko Community
                </p>
              </div>
            </div>
            <p className={LandStyle.landSize}>300 & 500 SQM</p>
            <div className={LandStyle.holdImageText}>
              <div>
                <img src={drainage} alt="drainage" />
                <p>Drainage</p>
              </div>
              <div>
                <img src={road} alt="road" />
                <p>Roads</p>
              </div>
              <div>
                <img src={cctvCamera} alt="cctvCamera" />
                <p>Surveillance</p>
              </div>
              <div>
                <img src={fence} alt="fence" />
                <p>Gated House</p>
              </div>
            </div>
            <div className={LandStyle.holdImageExploreButin}>
              <Link to={"/arizon-estate"} type="button">
                Explore
              </Link>
              {/* <a href="https://arizonestate.com/" target="_blank">
                <button
                  type="button"
                  onClick={() => navigate("/arizon-estate")}
                >
                  Explore
                </button>
              </a> */}
            </div>
          </div>

          <div
            className={LandStyle.holdAllDrainage2}
            data-aos="fade-right"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
          >
            <div className={LandStyle.holdImage}>
              <h4 className={LandStyle.SalesNo}>Selling</h4>
              <img src={zylusgroup} alt="kapital" />
              <div>
                <h1>Zylus Court Phase II</h1>
                <p>
                  <img
                    src={pins}
                    alt="pins"
                    className={LandStyle.locationPoint}
                  />
                  Opp. Beechwood Estate Bogije
                </p>
              </div>
            </div>
            <p className={LandStyle.landSize}>500 SQM</p>
            <div className={LandStyle.holdImageText}>
              <div>
                <img src={drainage} alt="drainage" />
                <p>Drainage</p>
              </div>
              <div>
                <img src={road} alt="road" />
                <p>Roads</p>
              </div>
              <div>
                <img src={cctvCamera} alt="cctvCamera" />
                <p>Surveillance</p>
              </div>
              <div>
                <img src={fence} alt="fence" />
                <p>Gated House</p>
              </div>
            </div>
            <div className={LandStyle.holdImageExploreButin}>
              <button
                type="button"
                onClick={() => navigate("/zylus-court-phase-II")}
              >
                Explore
              </button>
            </div>
          </div>

          <div
            className={LandStyle.holdAllDrainage2}
            data-aos="fade-right"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
          >
            <div className={LandStyle.holdImage}>
              <h4 className={LandStyle.SalesNo}>Selling</h4>
              <img src={sigmuNowSelling} alt="Not Available" />
              <div>
                <h1>Signum</h1>
                <p>
                  <img
                    src={pins}
                    alt="pins"
                    className={LandStyle.locationPoint}
                  />
                  Oreki community in proximity
                </p>
              </div>
            </div>
            <p className={LandStyle.landSize}>500 SQM</p>
            <div className={LandStyle.holdImageText}>
              <div>
                <img src={drainage} alt="drainage" />
                <p>Drainage</p>
              </div>
              <div>
                <img src={road} alt="road" />
                <p>Roads</p>
              </div>
              <div>
                <img src={cctvCamera} alt="cctvCamera" />
                <p>Surveillance</p>
              </div>
              <div>
                <img src={fence} alt="fence" />
                <p>Gated House</p>
              </div>
            </div>
            <div className={LandStyle.holdImageExploreButin}>
              <button type="button" onClick={() => navigate("/signum")}>
                Explore
              </button>
            </div>
          </div>

          <div
            className={LandStyle.holdAllDrainage2}
            data-aos="fade-right"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
          >
            <div className={LandStyle.holdImage}>
              <h4 className={LandStyle.SalesNo}>Selling</h4>
              <img src={treasure} alt="Rectangle 678" />
              <div>
                <h1>Treasure Park</h1>
                <p>
                  <img
                    src={pins}
                    alt="pins"
                    className={LandStyle.locationPoint}
                  />
                  Akanu Ibiam Airport,
                </p>
              </div>
            </div>
            <p className={LandStyle.landSize}>300 & 600 SQM</p>
            <div className={LandStyle.holdImageText}>
              <div>
                <img src={drainage} alt="drainage" />
                <p>Drainage</p>
              </div>
              <div>
                <img src={road} alt="road" />
                <p>Roads</p>
              </div>
              <div>
                <img src={cctvCamera} alt="cctvCamera" />
                <p>Surveillance</p>
              </div>
              <div>
                <img src={fence} alt="fence" />
                <p>Gated House</p>
              </div>
            </div>
            <div className={LandStyle.holdImageExploreButin}>
              <button type="button" onClick={() => navigate("/treasure-park")}>
                Explore
              </button>
            </div>
          </div>

          <div
            className={LandStyle.holdAllDrainage2}
            data-aos="fade-left"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
          >
            <div className={LandStyle.holdImage}>
              <h4 className={LandStyle.SalesNo}>Selling</h4>
              <img src={etiileNowSelling} alt="Rectangle 678" />
              <div>
                <h1>Eti Ile Phase 2</h1>
                <p>
                  <img
                    src={pins}
                    alt="pins"
                    className={LandStyle.locationPoint}
                  />
                  Lafenwa, Abeokuta
                </p>
              </div>
            </div>
            <p className={LandStyle.landSize}>300 & 500 SQM</p>
            <div className={LandStyle.holdImageText}>
              <div>
                <img src={drainage} alt="drainage" />
                <p>Drainage</p>
              </div>
              <div>
                <img src={road} alt="road" />
                <p>Roads</p>
              </div>
              <div>
                <img src={cctvCamera} alt="cctvCamera" />
                <p>Surveillance</p>
              </div>
              <div>
                <img src={fence} alt="fence" />
                <p>Gated House</p>
              </div>
            </div>
            <div className={LandStyle.holdImageExploreButin}>
              <button type="button" onClick={() => navigate("/eti-ile")}>
                Explore
              </button>
            </div>
          </div>

          <div
            className={LandStyle.holdAllDrainage2}
            data-aos="fade-left"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
          >
            <div className={LandStyle.holdImage}>
              <h4 className={LandStyle.Outofsales}>Sold Out</h4>
              <img src={lekkiprideiii} alt="Rectangle 678" />
              <div>
                <h1> Lekki Pride III </h1>
                <p>
                  <img
                    src={pins}
                    alt="pins"
                    className={LandStyle.locationPoint}
                  />
                  Ogombo Road, Abraham Adesanya
                </p>
              </div>
            </div>
            <p className={LandStyle.landSize}>300 & 500 SQM</p>
            <div className={LandStyle.holdImageText}>
              <div>
                <img src={drainage} alt="drainage" />
                <p>Drainage</p>
              </div>
              <div>
                <img src={road} alt="road" />
                <p>Roads</p>
              </div>
              <div>
                <img src={cctvCamera} alt="cctvCamera" />
                <p>Surveillance</p>
              </div>
              <div>
                <img src={fence} alt="fence" />
                <p>Gated House</p>
              </div>
            </div>
            <div className={LandStyle.holdImageExploreButin}>
              <button
                type="button"
                onClick={() => navigate("/lekki-pride-III")}
              >
                Explore
              </button>
            </div>
          </div>

          <div
            className={LandStyle.holdAllDrainage2}
            data-aos="fade-right"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
          >
            <div className={LandStyle.holdImage}>
              <h4 className={LandStyle.SalesNo}>Selling</h4>
              <img src={hilton} alt="Rectangle 678" />
              <div>
                <h1> Hilton City </h1>
                <p>
                  <img
                    src={pins}
                    alt="pins"
                    className={LandStyle.locationPoint}
                  />
                  Kurudu Abuja
                </p>
              </div>
            </div>
            <p className={LandStyle.landSize}>300 & 600 SQM</p>
            <div className={LandStyle.holdImageText}>
              <div>
                <img src={drainage} alt="drainage" />
                <p>Drainage</p>
              </div>
              <div>
                <img src={road} alt="road" />
                <p>Roads</p>
              </div>
              <div>
                <img src={cctvCamera} alt="cctvCamera" />
                <p>Surveillance</p>
              </div>
              <div>
                <img src={fence} alt="fence" />
                <p>Gated House</p>
              </div>
            </div>
            <div className={LandStyle.holdImageExploreButin}>
              <button type="button" onClick={() => navigate("/hilton-city")}>
                Explore
              </button>
            </div>
          </div>

          <div
            className={LandStyle.holdAllDrainage2}
            data-aos="fade-right"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
          >
            <div className={LandStyle.holdImage}>
              <h4 className={LandStyle.Outofsales}>Sold Out</h4>
              <img src={kapital} alt="kapital" />
              <div>
                <h1>Kapital City Estate</h1>
                <p>
                  <img
                    src={pins}
                    alt="pins"
                    className={LandStyle.locationPoint}
                  />
                  Epe, Lagos State
                </p>
              </div>
            </div>
            <p className={LandStyle.landSize}>300 & 500 SQM</p>
            <div className={LandStyle.holdImageText}>
              <div>
                <img src={drainage} alt="drainage" />
                <p>Drainage</p>
              </div>
              <div>
                <img src={road} alt="road" />
                <p>Roads</p>
              </div>
              <div>
                <img src={cctvCamera} alt="cctvCamera" />
                <p>Surveillance</p>
              </div>
              <div>
                <img src={fence} alt="fence" />
                <p>Gated House</p>
              </div>
            </div>
            <div className={LandStyle.holdImageExploreButin}>
              <Link to={"https://www.kapitalcityestate.com/"} type="button">
                Explore
              </Link>
            </div>
          </div>

          <div
            className={LandStyle.holdAllDrainage2}
            data-aos="fade-right"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
          >
            <div className={LandStyle.holdImage}>
              <h4 className={LandStyle.Outofsales}>Sold Out</h4>
              <img src={tiwacom} alt="Rectangle 678" />
              <div>
                <h1>Tiwa Commercial Hub</h1>
                <p>
                  <img
                    src={pins}
                    alt="pins"
                    className={LandStyle.locationPoint}
                  />
                  Aiyetoro, Lekki Epe Expressway
                </p>
              </div>
            </div>
            <p className={LandStyle.landSize}>500 & 1000 SQM</p>
            <div className={LandStyle.holdImageText}>
              <div>
                <img src={drainage} alt="drainage" />
                <p>Drainage</p>
              </div>
              <div>
                <img src={road} alt="road" />
                <p>Roads</p>
              </div>
              <div>
                <img src={cctvCamera} alt="cctvCamera" />
                <p>Surveillance</p>
              </div>
              <div>
                <img src={fence} alt="fence" />
                <p>Gated House</p>
              </div>
            </div>
            <div className={LandStyle.holdImageExploreButin}>
              <button type="button" onClick={() => navigate("/single-land")}>
                Explore
              </button>
            </div>
          </div>

          <div
            className={LandStyle.holdAllDrainage2}
            data-aos="fade-left"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
          >
            <div className={LandStyle.holdImage}>
              <h4 className={LandStyle.Outofsales}>Sold Out</h4>
              <img src={tiwagar} alt="Rectangle 678" />
              <div>
                <h1>Tiwa Garden City</h1>
                <p>
                  <img
                    src={pins}
                    alt="pins"
                    className={LandStyle.locationPoint}
                  />
                  Aiyetoro, lekki epe expressway
                </p>
              </div>
            </div>
            <p className={LandStyle.landSize}>500 & 250 SQM</p>
            <div className={LandStyle.holdImageText}>
              <div>
                <img src={drainage} alt="drainage" />
                <p>Drainage</p>
              </div>
              <div>
                <img src={road} alt="road" />
                <p>Roads</p>
              </div>
              <div>
                <img src={cctvCamera} alt="cctvCamera" />
                <p>Surveillance</p>
              </div>
              <div>
                <img src={fence} alt="fence" />
                <p>Gated House</p>
              </div>
            </div>
            <div className={LandStyle.holdImageExploreButin}>
              <a href="https://www.tiwagardencity.com/phase1" target="_self">
                Explore
                {/* <button type="button">Explore</button> */}
              </a>
            </div>
          </div>

          {/* <div
            className={LandStyle.holdAllDrainage2}
            data-aos="fade-right"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
          >
            <div className={LandStyle.holdImage}>
              <h4 className={LandStyle.Outofsales}>Sold Out</h4>
              <img src={zyluscourt} alt="Rectangle 678" />
              <div>
                <h1>Zylus court 2</h1>
                <p>
                  <img
                    src={pins}
                    alt="pins"
                    className={LandStyle.locationPoint}
                  />
                  Opp. Beechwood Estate Bogije
                </p>
              </div>
            </div>
            <p className={LandStyle.landSize}>300 & 500 SQM</p>
            <div className={LandStyle.holdImageText}>
              <div>
                <img src={drainage} alt="drainage" />
                <p>Drainage</p>
              </div>
              <div>
                <img src={road} alt="road" />
                <p>Roads</p>
              </div>
              <div>
                <img src={cctvCamera} alt="cctvCamera" />
                <p>Surveillance</p>
              </div>
              <div>
                <img src={fence} alt="fence" />
                <p>Gated House</p>
              </div>
            </div>
            <div className={LandStyle.holdImageExploreButin}>
              <button
                type="button"
                onClick={() => navigate("/zylus-court-phase-II")}
              >
                Explore
              </button>
            </div>
          </div> */}

          {/* <div className={LandStyle.holdAllDrainage2}>
                        <div className={LandStyle.holdImage}>
                            <h4 className={LandStyle.SalesNo}>Selling</h4>
                            <img src={lekki2} alt="Rectangle 678" />
                            <div>
                                <h1> Lekki Pride II </h1>
                                <p><img src={pins} alt="pins" className={LandStyle.locationPoint} />Ogombo Road</p>
                            </div>
                        </div>
                        <p className={LandStyle.landSize}>300, 500 & 1000 SQM</p>
                        <div className={LandStyle.holdImageText}>
                            <div>
                                <img src={drainage} alt="drainage" />
                                <p>Drainage</p>
                            </div>
                            <div>
                                <img src={road} alt="road" />
                                <p>Roads</p>
                            </div>
                            <div>
                                <img src={cctvCamera} alt="cctvCamera" />
                                <p>Surveillance</p>
                            </div>
                            <div>
                                <img src={fence} alt="fence" />
                                <p>Gated House</p>
                            </div>
                        </div>
                        <div className={LandStyle.holdImageExploreButin}>
                            <button type="button"
                            // onClick={() => navigate("/lekki-pride-III")}
                            >Explore</button>
                        </div>
                    </div> */}
          <div
            className={LandStyle.holdAllDrainage2}
            data-aos="fade-left"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
          >
            <div className={LandStyle.holdImage}>
              <h4 className={LandStyle.Outofsales}>Sold Out</h4>
              <img src={westernHilton} alt="Rectangle 678" />
              <div>
                <h1> Western Hilltop </h1>
                <p>
                  <img
                    src={pins}
                    alt="pins"
                    className={LandStyle.locationPoint}
                  />
                  Ikola Alagbado
                </p>
              </div>
            </div>
            <p className={LandStyle.landSize}>300, 500 & 1000 SQM</p>
            <div className={LandStyle.holdImageText}>
              <div>
                <img src={drainage} alt="drainage" />
                <p>Drainage</p>
              </div>
              <div>
                <img src={road} alt="road" />
                <p>Roads</p>
              </div>
              <div>
                <img src={cctvCamera} alt="cctvCamera" />
                <p>Surveillance</p>
              </div>
              <div>
                <img src={fence} alt="fence" />
                <p>Gated House</p>
              </div>
            </div>
            <div className={LandStyle.holdImageExploreButin}>
              <button
                type="button"
                onClick={() => navigate("/western-hilltop")}
              >
                Explore
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className={LandStyle.holdAnytime}
        data-aos="fade-down"
        data-aos-offset="200"
        data-aos-easing="ease-in-sine"
        data-aos-duration="600"
      >
        <div>
          <h1 className={LandStyle.Reach}>You Can Reach Us Anytime</h1>
          <p className={LandStyle.question}>
            Have a question or doubt about our property?
          </p>
        </div>
        <button className={LandStyle.callUs}>
          <IoCall />
          Call us
        </button>
      </div>
    </section>
  );
}

export default Lands;